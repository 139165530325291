import Button from "../forms/Button";
import React from "react";
import "./NeedHelpButton.css";
import {BsHeart} from "react-icons/bs";

export default function SponsorButton() {

    const external = (url, newWindow = false) => {
        if (newWindow === true) {
            return () => window.open(url, '_blank', 'location=yes,scrollbars=yes,status=yes')
        } else {
            return () => window.location.href = url;
        }
    }

    

}